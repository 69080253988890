<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="18" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                class="form_title-input"
                name="linkName"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="document_files_array.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <small
                v-if="$v.form.documnet.$dirty && !$v.form.documnet.required"
                class="error-message"
              >
                Требуется файл
              </small>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" class="form_title" style="padding-top: 2px">
              Изображение:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <small
                v-if="$v.form.photo.$dirty && !$v.form.photo.required"
                class="error-message"
              >
                Необходимо изображение
              </small>
              <div class="committee-image">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Позиция: </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                v-model="form.position"
                type="number"
                name="position"
                placeholder="Позиция"
              />
            </a-col>
          </a-row>

          <a-row style="margin-top: 15px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="4" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button type="primary" @click="submit">
                  <a-icon type="check" />Добавить
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import InputFileItem from "@/components/utils/input-file-item"
import cloneDeep from "lodash/cloneDeep"

export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
    InputFileItem
  },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        is_active: false,
        position: 1,
        photo: "",
        file: "",
        documnet: ""
      },
      langTab: "oz",
      image: null,
      image_url: "",
      document_files_array: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      let newData = cloneDeep(this.form)
      newData = {
        ...newData
      }
      if (typeof newData.photo === "string") {
        delete newData.photo
      }
      let f = new FormData()
      Object.keys(newData).forEach((key) => {
        if (newData[key] === null) {
          newData[key] = ""
        }
        f.append(key, newData[key])
        // f === "photo" && f.append(key, this.form[key], this.form[key].name)
      })
      this.document_files_array.forEach((item) => {
        f.append("file", item.binary_file, item.binary_file.name)
      })

      try {
        let res = await this.$api.post("/admin/common/resource/create/", f)
        if (res && res.status === 201) {
          this.$message.success("Создана успешно")
          // await this.$router.push({ name: "resources" })
          this.$router.go(-1)
        } else {
          this.$message.error("Ошибка при добавлении")
        }
      } catch (e) {
        this.$message.error("Ошибка при добавлении")
        console.log(e)
      }
    },

    deleteDocumentFileFromState(e) {
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.document_files_array, e)
    },

    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0])
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.photo = e.target.files[0]
    },

    handleDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
          this.form.documnet = item
        })
      }
      console.log("array", this.document_files_array)
      this.$refs.documentRef.value = null
    }
  },
  validations: {
    form: {
      photo: {
        required
      },
      documnet: {
        required
      }
    }
  }
}
</script>

<style scoped>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
